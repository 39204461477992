<template>
    <div class="flex column center" style="margin-bottom:4rem;">
        <div class="new_box">
            <div class="titlee">历思承担厦门首起环境公益诉讼案环境损害司法鉴定工作</div>
            <p class="time">2018-09-25</p>
            <div class="titlee2">
                环境“有价”，损害担责
            </div>

            <p class="space_content">没有办理任何资质，厦门市一男子却擅自在承包地上开设大规模养猪场，造成大面积环境污染。不久前，厦门市中级人民法院对原告福建省环保志愿者协会与被告叶某环境污染责任纠纷公益诉讼一案作出一审判决，判令被告叶某支付环境污染赔偿款91.75万元至政府财政专户用于环境生态修复，并在省级以上媒体向社会公开赔礼道歉。</p>
            <p class="space_content">作为厦门市首例环境公益诉讼案，本案受到了广泛关注。环境“有价”，违法必究，也无疑给社会大众敲响了警钟。</p>
            <div class="titlee2">
                追究责任 环保协会将养猪人告上法庭
            </div>

            <p class="space_content">2017年10月，福建省环保志愿者协会的一纸诉状，将厦门男子叶某告上了法庭。</p>

            <p class="space_content">案件的原告福建省环保志愿者协会是民间组织，由热衷环保公益事业的企业、志愿者等参加的公益性和非营利性社团组织。为何要状告个人？事情还得从十五年前说起。</p>

            <p class="space_content">原来，在2003年至2005年期间，叶某在未取得相应的备案、审批手续情形下，擅自在承包地块上陆续建设猪舍、管理房及固化地用于开办规模化生猪养殖场，养殖场总占地面积达1.4万多平方米，生猪养殖规模近1.5万头。经福建历思司法鉴定所鉴定，该案件造成了至少90多万的环境损害价值。</p>
            <p class="space_content">2016年11月，厦门市环保局同安分局对叶某作出责令改正违法行为决定书，责令其停止规模化生猪养殖，并自行处理生猪、关闭或拆除养殖场。2017年1月，该养殖场被拆除；同时，城管部门认为叶某涉嫌非法占用农用地罪，依法将该案移送公安机关处理；2017年8月，该养殖场被确定为生猪退养转产转业项目；2018年1月，该生猪退养转产转业项目经验收合格……</p>
            <p class="space_content">行政处罚虽然开出，后续整改也在进行中，但已造成的环境损害如何赔偿？福建省环保志愿者协会认为，被告叶某应承担环境损害赔偿责任。为此，协会向厦门中院提起诉讼:请求法院依法判令三被告赔偿环境污染造成的损失150万元，款项支付至财政专户用于生态修复，并公开赔礼道歉。</p>

            <div class="titlee2">
                如何定损 中院发挥能动性解决审理难点
            </div>

            <p class="space_content">
                “本案争议的主要焦点，在于叶某规模化生猪养殖环境污染现场灭失后，如何认定环境损害后果。”本案的承办法官王池解析称，“特别是在环境污染方面公共利益的损害后果更是本案审理的难点。”
        
            </p>
            <p class="space_content">
                面对审理难点，厦门中院发挥出了司法能动性。在本案当事人因环境污染现场灭失而对损害后果存有重大争议下，中院根据《最高人民法院关于审理环境侵权责任纠纷案件适用法律若干问题的解释》第八条“对查明环境污染案件事实的专门性问题，可以委托具备相关资格的司法鉴定机构出具鉴定意见或者由国务院环境保护主管部门推荐的机构出具检验报告、检测报告、评估报告或者检测数据”的规定，启动环境损害后果司法鉴定。
            </p>
            <p class="space_content">
                “公益诉讼与一般的民事诉讼有所区别，注意到这一点，就要发挥适度的职权主义原则，在当事人对本案是否鉴定及鉴定机构选定上出现争议时，依职权启动对环境损害后果的鉴定，并指定相应资质的鉴定机构。”王池介绍说，最后是采用虚拟治理成本法出具环境损害后果鉴定意见。
            </p>
            <p class="space_content">
                虚拟治理成本法属环境价值评估方法之一，在目前环境损害鉴定评估实践中逐步应用。根据国家相关法律规定，该案适用虚拟治理成本法，解决了环境污染现场灭失下损害后果评估鉴定的难题。
            </p>
            <p class="space_content">
                在本案审理过程中，2018年5月经现场勘验，该养殖现场已灭失，全部改造为蔬菜大棚。厦门中院依职权将本案涉及的环境损害后果评估移送选定的历思司法鉴定所进行鉴定。鉴定机构作出的意见书载明：叶某因生猪养殖违法排放污染物造成的环境损害总价值为91.75万元。
            </p>
            <p class="space_content">
                厦门中院经审理认为，叶某在禁养区内从事规模化生猪养殖，未按照相关规定进行环境影响评价且环境污染综合化处理，造成环境污染损害后果。福建历思司法鉴定所作为具备相关资格的司法鉴定机构，其适用虚拟治理成本法进行评估，并无不当，且符合本案的实际情形，故其鉴定意见可以作为本案环境损害后果的认定依据。
            </p>
            <p class="space_content">
                因此，厦门中院作出一审判决，要求养殖场老板叶某支付环境污染赔偿款91.75万元至政府财政专户，用于环境生态修复。
            </p>
            <div class="titlee2">
                敲响警钟 保护环境应发动全社会力量
            </div>
            <p class="space_content">
                生态环境属于典型的公共资源，为了减少“公地悲剧”现象，有效遏制“搭生态环境便车”行为，贯彻“环境有价、利用付费、损害担责”的基本原则，我国相关法律明确规定，符合法律规定的社会组织有权对污染环境损害社会公共利益的行为提起环境民事公益诉讼。
            </p>
            <p class="space_content">
                本案中，原告福建省环保志愿者协会依法向法院提交了社会团体法人登记证书、协会章程、相关荣誉证书等，符合“专门从事环境保护公益活动”等要求，法院认为其具备原告主体资格，所以予以受理。
            </p>
            <p class="space_content">
                “以往，针对环境破坏提出的诉讼主体主要是政府部门，而在该起案件中，是由社会公益组织作为原告提出的。有了民间力量的支持，在推动环保事业发展的道路上，环保部门不再是‘单枪匹马’。”厦门市环保局同安分局副局长张碰奎说。
            </p>
            <p class="space_content">
                “这样可以有效地保护国家利益和社会秩序不受违法行为的侵害，把违法行为消灭在萌芽状态。”张碰奎说，“这个案件从一定程度上也说明了环境是‘有价’的，让破坏环境者对所作所为付出经济赔偿，很有必要。不管是谁，无论从事什么样的生产经营活动，都应该牢记自身需要承担的保护环境的社会责任和法律责任，谁都不能肆无忌惮以牺牲环境为代价谋求利益。”
            </p>
        </div>
        <div class="flex wrap col442" style="width:100%;text-align:left;margin-top:1rem">
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex" style="margin-right:10%">
                    <p style="white-space:nowrap">上一篇：</p>
                    <router-link class="a" :to="'/case/env_case/2'"><p class="ellipsis1">全国首例！历思助力技术调查官参审污染环境案件，宣判啦~</p></router-link>

                </div>
            </div>
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex">
                    <p style="white-space:nowrap">下一篇：</p>
                    无
                    
                </div>
            </div>
            <div class="col2 flex  end1 center">
                <router-link class="a" style="color:#fff" :to="'/case/env_case'"><el-button type="primary">返回列表</el-button></router-link >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return { 
            data:{

            }
        }
    },
}
</script>